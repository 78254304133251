<script>
  import Spinner from "svelte-spinner";
  import {
    authenticate,
    login,
    logout,
    ready,
    loggedIn,
    waiting,
    errorWithSSO,
    errorWithCustomInfo,
    errorWithCustomInfoAttributes,
  } from "./auth.js";

  import { notallowed } from "./notallowed.js";

  import { UsersIcon } from "svelte-feather-icons";

  import { FrownIcon } from "svelte-feather-icons";

  const handleMenuAction = (event) => {
    if (event.detail === "logout") logout();
  };

  function navigateTo(url) {
    window.location.href = url;
  }

  let logging_in = false;
  let logging_out = false;

  let login_with_loading = () => {
    logging_in = true;
    login();
  };

  let logout_with_loading = () => {
    logging_out = true;
    logout();
  };

  const profile = window.env ? window.env.PROFILE : {"env":{"isProd":true,"CLIENT_ID":"ots","APPLICATION":"Open1Org","SSO_URL":"https://open1sso.maroussi.gr/openonesso/","BASE_URL":"https://open1org.maroussi.gr/back/","TUKANGA_URL":"https://open1process.maroussi.gr/tukanga/"}}.env.PROFILE || "ots";

  const icon_url = profile == "alfaware" ? "/alfaware.png" : "/favicon.png";

  const app_title = profile == "alfaware" ? "AlfaNG ORG" : "Open1 | ORG";

  authenticate();
</script>

<svelte:head>
  <link rel="icon" type="image/png" href={icon_url} />
  <title>{app_title}</title>
</svelte:head>

{#if $errorWithSSO}
<div style="width:500px; margin:auto">
  <h2>
    <!-- Error with SSO -->
    Σφάλμα σύνδεσης με SSO.
  </h2>

  {#if $errorWithCustomInfo}
    <h1>
      <!-- Error with custom info -->
      Σφάλμα στις πληροφορίες σύνδεσης.
    </h1>
  {/if}

  {#if $errorWithCustomInfoAttributes}
    <h1>
      <!-- Error with custom info common attributes -->
      Σφάλμα στις πρόσθετες πληροφορίες σύνδεσης.
    </h1>
  {/if}
</div>
  <!-- 
      <button
        on:click={logout_with_loading}
        class="button is-light"
        class:is-loading={logging_out}>
        Αποσύνδεση και προσπάθεια ξανά
      </button> -->
{:else if $loggedIn}
  {#if $ready}
    {#if $notallowed}
      <div class="container">
        <div
          class="has-text-centered"
          style="margin-top: 4rem; color: lightgrey"
        >
          <FrownIcon size="7.5x" />
        </div>
        <div class="is-size-5 has-text-centered" style="margin-top: 2rem">
          Δεν ήταν δυνατή η πρόσβαση στο οργανόγραμμα.
        </div>

        <div class="block has-text-centered" style="margin-top:2rem">
          <button
            on:click={logout_with_loading}
            class="button is-link is-large"
            class:is-loading={logging_out}
          >
            Έξοδος
          </button>
        </div>
      </div>
    {:else}
      <slot />
    {/if}
  {:else}
    <div class="center-me">
      <Spinner size="80" speed="750" thickness="3" gap="40" />
    </div>
  {/if}
{:else if $waiting}
  <div class="center-me delay-spinner">
    <Spinner size="80" speed="750" thickness="3" gap="40" />
  </div>
{:else}
  <div class="center-me">
    <div class="has-text-grey-dark">
      <div class="title has-text-grey-dark">
        &nbsp;
        <div class="column has-text-centered">
          {#if profile == "alfaware"}
            <div class="level level-item has-text-centered">
              <img src={icon_url} width="24" alt="AlfaNG" />
              <div class="is-size-3">ORG</div>
            </div>
          {:else}
            <div style="opacity:0.5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="96"
                height="96"
                viewBox="0 0 24 24"
                ><path
                  fill="currentColor"
                  d="M13 11V9h-2l.025 2H3.5v4h2v-2H11v2h2v-2h5.505l-.005 2h2v-4H13zm-1-9a2.5 2.5 0 1 0 2.5 2.5A2.5 2.5 0 0 0 12 2Zm0 3.5a1 1 0 1 1 1-1a1 1 0 0 1-1 1ZM4.5 17A2.5 2.5 0 1 0 7 19.5A2.5 2.5 0 0 0 4.5 17Zm0 3.5a1 1 0 1 1 1-1a1 1 0 0 1-1 1ZM12 17a2.5 2.5 0 1 0 2.5 2.5A2.5 2.5 0 0 0 12 17Zm0 3.5a1 1 0 1 1 1-1a1 1 0 0 1-1 1Zm7.5-3.5a2.5 2.5 0 1 0 2.5 2.5a2.5 2.5 0 0 0-2.5-2.5Zm0 3.5a1 1 0 1 1 1-1a1 1 0 0 1-1 1Z"
                /></svg
              >
            </div>
            <div class="is-size-2">ORG</div>
          {/if}
        </div>
      </div>
    </div>
    <div class="has-text-centered">
      <button
        on:click={login_with_loading}
        class="button is-large is-link"
        class:is-loading={logging_in}
      >
        Είσοδος
        <!-- with SSO -->
      </button>
    </div>
  </div>
{/if}

<style>
  .center-me {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .delay-spinner {
    -webkit-animation: 3s ease 0s normal forwards 1 fadein;
    animation: 3s ease 0s normal forwards 1 fadein;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadein {
    0% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>
